<template>
  <div class="">
    <el-dialog
      v-model="dialogVisible"
      title="项目咨询"
      top="30vh"
      :before-close="handleClose"
    >
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        label-width="120px"
        class="demo-ruleForm"
        :size="formSize"
        status-icon
      >
        <el-form-item label="咨询类型" prop="type">
          <el-select style="width: 100%;" v-model="ruleForm.type" allow-create
            default-first-option
            filterable
            placeholder="请选择咨询类型">
            <el-option label="web3应用开发" value="web3应用开发" />
            <el-option label="NFT数字藏品开发" value="NFT数字藏品开发" />
            <el-option label="数字藏品系统定制" value="数字藏品系统定制" />
            <el-option label="元宇宙虚拟场地服务" value="元宇宙虚拟场地服务" />
            <el-option label="元宇宙应用开发" value="元宇宙应用开发" />
            <el-option label="元宇宙游戏开发" value="元宇宙游戏开发" />
          </el-select>
        </el-form-item>
        <el-form-item label="您的称呼" prop="name">
          <el-input v-model="ruleForm.name" />
        </el-form-item>
        <el-form-item label="您的联系方式" prop="phone">
          <el-input v-model="ruleForm.phone" />
        </el-form-item>
        <el-form-item>
          <el-button class="b-left" type="primary" @click="submitForm(ruleFormRef)"
            >立即咨询</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script setup>
  import { ref, reactive, defineEmits } from 'vue'
  import { ElMessage } from 'element-plus'
  import axios from 'axios'
  const emits = defineEmits(['showClose'])
  const formSize = ref('default')
  const ruleFormRef = ref()
  const ruleForm = reactive({
    type: '',
    name: '',
    phone: ''
  })
  const rules = reactive({
    name: [
      { required: true, message: '请输入您的称呼', trigger: 'blur' }
    ],
    type: [
      { required: true, message: '请选择咨询类型', trigger: 'blur' }
    ],
    phone: [
      {
        required: true,
        message: '请输入您的联系方式',
        trigger: 'blur'
      }
    ]
  })

  const submitForm = async (formEl) => {
    if (!formEl) return
    await formEl.validate((valid, fields) => {
      if (valid) {
        // axios.get('http://47.101.153.209:8114/feedBack/sendFeedBack?type=' + ruleForm.type + '&name=' + ruleForm.name + '&phone=' + ruleForm.phone).then(res => {
        // axios.get('http://47.101.153.209:8114/feedBack/sendFeedBack', {
        axios.get('https://metaapi.0block.com/feedBack/sendFeedBack', {
          params: {
            ...ruleForm
          }
        }).then(res => {
          if  (res.data.code === 0) {
            ElMessage.success('提交成功')
            setTimeout(() => {
              dialogVisible.value = false
              ruleForm.type = ''
              ruleForm.name = ''
              ruleForm.phone = ''
              emits('showClose')
            }, 1000);
          } else {
            ElMessage.error(res.data.message)
          }
        })
      }
    })
  }

  const resetForm = (formEl) => {
    if (!formEl) return
    formEl.resetFields()
  }
  const dialogVisible = ref(true)
  const handleClose = (done) => {
    done()
    emits('showClose')
  }
</script>

<style lang="scss" scoped>
  @media screen and (min-width: 500px) {
    ::v-deep .el-dialog {
      width: 600px;
    }
    .b-left {
      margin-left: 107px;
    }
    .dialog-footer button:first-child {
      margin-right: 10px;
    }
    ::v-deep .el-form-item--default .el-form-item__label {
      font-size: 16px;
    }
    ::v-deep .el-input__inner {
      font-size: 16px;
    }
    ::v-deep .el-select-dropdown__item {
      font-size: 16px !important;
    }
  }
  @media screen and (min-width:359px) and (max-width: 500px) {
    ::v-deep .el-dialog {
      width: 350px;
    }
    ::v-deep .el-dialog__body {
      padding: 10px 20px;
    }
    ::v-deep .el-dialog__header {
      padding: 0px;
    }
    .dialog-footer button:first-child {
      margin-right: 10px;
    }
    ::v-deep .el-form-item--default .el-form-item__label {
      font-size: 12px;
    }
    ::v-deep .el-input__inner {
      font-size: 14px;
    }
    ::v-deep .el-select-dropdown__item {
      font-size: 14px !important;
    }
    ::v-deep .el-form-item__label {
      width: 100px !important;
    }
  }
</style>
