import { ref, onMounted, onBeforeUnmount } from 'vue'
export default function (color, style) {
  function handleScroll() {
    const scrollTop = ref(window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop)
    if (document.querySelector('#webHeader')) {
      const offsetTop = ref(document.querySelector('#webHeader').offsetTop.value)
      if (scrollTop.value <= 100) {
        offsetTop.value = 0 - Number(scrollTop.value)
        document.querySelector('#webHeader').style.top = offsetTop.value + 'px'
      } else {
        document.querySelector('#webHeader').style.top = '0px'
      }
      if (scrollTop.value) {
        // style.backgroundColor = `rgba(44, 44, 44,${scrollTop.value /
        //   (scrollTop.value + 80)})`
        style.backgroundColor = `rgba(0, 0, 0,0.9)`
        // style.backdropFilter = 'blur(15px)'
        color = true
      } else if (scrollTop.value === 0) {
        style.backgroundColor = 'transparent'
        // style.backdropFilter = 'blur(0px)'
        color = false
      }
    }
  }
  onMounted(() => {
    window.addEventListener('scroll', handleScroll)
  })
  onBeforeUnmount(() => {
    window.removeEventListener('scroll', handleScroll)
  })
}
