<template>
  <div class="home">
    <div @mouseover="mouseLeave" @click="mouseLeave">
      <NftCollection @consultEmit="consultEmit" />
      <WebTrabsition />
      <Web3 />
      <Metaverse @consultEmit="consultEmit" />
    </div>
    <div v-if="viewport > 500">
      <div class="sidebar c-o">
        <img ref="img1" class="img1" @mouseover="imgActive(1)" src="~@/assets/phone.png" alt="">
        <img ref="img2" class="img2" @mouseover="imgActive(2)" src="~@/assets/wechat.png" alt="">
      </div>
      <div v-if="tab === 1" class="emerge_phone c-o">
        <div class="emerge_phone_title">官方电话</div>
        <div class="emerge_phone_content">电话客服在线时间：工作日  9:00-19:00</div>
        <div class="emerge_phone_contact">18382232368</div>
      </div>
      <div class="divide"></div>
      <div v-if="tab === 2" class="emerge_wx c-o">
        <img src="~@/assets/code.png" alt="">
        <div class="wx_title">
          添加微信号<br/>获得更多相关咨询
        </div>
      </div>
    </div>
    <div v-if="viewport < 500 && scrollTop >= 2100">
      <div class="sidebar c-o">
        <img ref="img1" class="img1" @mouseover="imgActive(1)" src="~@/assets/phone.png" alt="">
        <img ref="img2" class="img2" @mouseover="imgActive(2)" src="~@/assets/wechat.png" alt="">
      </div>
      <div v-if="tab === 1" class="emerge_phone c-o">
        <div class="emerge_phone_title">官方电话</div>
        <div class="emerge_phone_content">电话客服在线时间：工作日  9:00-19:00</div>
        <div class="emerge_phone_contact">18382232368</div>
      </div>
      <div class="divide"></div>
      <div v-if="tab === 2" class="emerge_wx c-o">
        <img src="~@/assets/code.png" alt="">
        <div class="wx_title">
          添加微信号<br/>获得更多相关咨询
        </div>
      </div>
    </div>
    <Consult v-if="dialogVisible === true" @showClose="showClose" />
    <Footer @click="mouseLeave" />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import NftCollection from './nftCollection/index.vue'
import WebTrabsition from './webTransition/index.vue'
import Web3 from './web3/index.vue'
import Metaverse from './metaverse/index.vue'
import Footer from '@/components/footer.vue'

import Consult from '@/components/consult.vue'

import { showDiv } from '@/utils/tool.js'

window.onscroll = () => {
  showDiv('web3', 'wgt-fade-animate')
  showDiv('web3', 'appear')
  showDiv('webTransition', 'wgt-fade-animate')
  showDiv('webTransition', 'appear')
  showDiv('metaverse', 'wgt-fade-animate')
  showDiv('metaverse', 'appear')
}
const img1 = ref(null)
const img2 = ref(null)
const tab = ref(0)
const store = useStore()

const imgActive = (type) => {
  tab.value = type
  if (type === 1) {
    img1.value.style.opacity = 1
    img2.value.style.opacity = 0.5
  } else {
    img1.value.style.opacity = 0.5
    img2.value.style.opacity = 1
  }
}
const mouseLeave = () => {
  img1.value.style.opacity = 0.5
  img2.value.style.opacity = 0.5
  tab.value = 0
}
const dialogVisible = ref(false)
const consultEmit = () => {
  dialogVisible.value = true
}
const showClose = () => {
  dialogVisible.value = false
}
computed(()=> {
  return store.state.hideClose
})
const viewport = ref(window.innerWidth);
function innerWindow() {
  viewport.value = window.innerWidth;
}

const scrollTop = ref(0)
const handleScroll = () => {
  scrollTop.value = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
}

onMounted(() => {
  window.addEventListener("resize", innerWindow);
  window.addEventListener('scroll', handleScroll)
})
</script>

<style lang="scss" scoped>
  @media screen and (min-width: 500px) {
    .sidebar {
      position: fixed;
      right: 30px;
      top: 50%;
      z-index: 999;
      width: 64px;
      height: 128px;
      margin-top: -64px;
      background: #3b3f50;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      img {
        width: 32px;
        height: 32px;
        opacity: 0.5;
      }
      .img2 {
        margin-top: 30px;
      }
    }
    .emerge_phone {
      position: fixed;
      right: 110px;
      top: 50%;
      z-index: 999;
      width: 310px;
      height: 110px;
      margin-top: -55px;
      background: #FFFFFF;
      animation: bounceInRight 1s 0s linear forwards;
      padding: 15px;
      &_title {
        font-size: 18px;
        text-align: left;
        color: #000;
      }
      &_content {
        padding-top: 8px;
        font-size: 15px;
        color: rgba(170, 170, 170, 1);
        text-align: left;
      }
      &_contact {
        font-size: 18px;
        color: rgba(69, 95, 251, 1);
        text-align: left;
        padding-top: 13px;
      }
    }
    .emerge_wx {
      position: fixed;
      right: 110px;
      top: 50%;
      z-index: 999;
      margin-top: -120px;
      width: 180px;
      height: 240px;
      background: #FFFFFF;
      animation: bounceInRight 1s 0s linear forwards;
      display: flex;
      align-items: center;
      flex-direction: column;
      img {
        width: 140px;
        margin-top: 20px;
        height: 140px;
      }
      .wx_title {
        padding-top: 10px;
        font-size: 14px;
        color: #000;
        line-height: 24px;
      }
    }
    .divide {
      position: fixed;
      right: 94px;
      top: 50%;
      z-index: 999;
      margin-top: -120px;
      width: 15px;
      height: 240px;
    }
  }
  @media screen and (min-width:359px) and (max-width: 500px) {
    .sidebar {
      position: fixed;
      right: 10px;
      top: 50%;
      z-index: 999;
      width: 50px;
      height: 100px;
      margin-top: -64px;
      background: #3b3f50;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      img {
        width: 32px;
        height: 32px;
        opacity: 0.5;
      }
      .img2 {
        margin-top: 20px;
      }
    }
    .emerge_phone {
      position: fixed;
      right: 70px;
      top: 50%;
      z-index: 999;
      width: 270px;
      height: 95px;
      margin-top: -105px;
      background: #FFFFFF;
      animation: bounceInRight 1s 0s linear forwards;
      padding: 10px;
      &_title {
        font-size: 16px;
        text-align: left;
        color: #000;
      }
      &_content {
        padding-top: 8px;
        font-size: 14px;
        color: rgba(170, 170, 170, 1);
        text-align: left;
      }
      &_contact {
        font-size: 16px;
        color: rgba(69, 95, 251, 1);
        text-align: left;
        padding-top: 10px;
      }
    }
    .emerge_wx {
      position: fixed;
      right: 70px;
      top: 50%;
      z-index: 999;
      margin-top: -120px;
      width: 160px;
      height: 190px;
      background: #FFFFFF;
      animation: bounceInRight 1s 0s linear forwards;
      display: flex;
      align-items: center;
      flex-direction: column;
      img {
        width: 120px;
        margin-top: 10px;
        height: 120px;
      }
      .wx_title {
        padding-top: 10px;
        font-size: 14px;
        color: #000;
        line-height: 20px;
      }
    }
    .divide {
      position: fixed;
      right: 94px;
      top: 50%;
      z-index: 999;
      margin-top: -120px;
      width: 10px;
      height: 200px;
    }
  }
  @keyframes bounceInRight {
    from, 60%, 75%, 90%, to {animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);}
    0% {opacity: 0;transform: translate3d(120px, 0, 0);}
    60% {opacity: 1;transform: translate3d(25px, 0, 0);}
    75% {transform: translate3d(-10px, 0, 0);}
    90% {transform: translate3d(5px, 0, 0);}
    100% {opacity: 1;transform: none;}
  }

</style>
