<template>
  <div class="metaverseApplications wgt-fade-animate appear">
    <div class="exploitation" id="metaverseOne">
      <div class="exploitation_title">
        <img src="~@/assets/metaverse/useTitle.png" alt="" />
      </div>
      <div class="exploitation_content o-8">
        元宇宙重新定义了人与空间的关系，AR、VR、云计算、5G和区块链等技术搭建了通往元宇宙等通道，<br />创建了虚拟与现实融合等交互方式，并正在改变和颠覆我们等生活。
      </div>
      <div class="exploitation_consult c-o" ref="consultSubmit"
        @click="consultShow"
        @mouseover="mouseOver"
        @mouseleave="mouseLeave">
        立即咨询
      </div>
      <!-- <div class="pullDown o-p-3">
        <img src="~@/assets/pullDown.png" alt="">
      </div> -->
    </div>
    <div class="exhibition" id="metaverseTwo">
      <div class="exhibitionContent">
        <div class="exhibition_title">
          虚拟会展
        </div>
        <div class="exhibition_content o-8">
          为以来自世界的各大厂商提供24小时的商品展示平台
        </div>
      </div>
    </div>
    <div class="scenery" id="metaverseThree">
      <div class="sceneryContent">
        <div class="scenery_title">
          虚拟景点开发
        </div>
        <div class="scenery_content o-8">
          以数字化技术打造新业态，既是工具也是重构；<br />最终目的是要让游客来得多、留得久，增加游客的粘性
        </div>
      </div>

    </div>
    <div class="world" id="metaverseFour">
      <div class="exhibitionContent">
        <div class="world_title">
          虚拟世界的开发
        </div>
        <div class="world_content o-8">
          基于区块链技术搭建一个元宇宙世界，用户对虚拟资产拥有所有权和自治权，<br />允许用户创建和运营世界内的任何物品和资产，可购买“土地”、“房产”、“汽车”、“飞船”等资产
        </div>
      </div>

    </div>
    <div class="education" id="metaverseFive">
      <div class="sceneryContent">
        <div class="education_title">
          虚拟教育开发
        </div>
        <div class="education_content o-8">
          校园的数字化发展带来了信息的数字化，<br />为了将校园数字化展现得更美妙，360度全景展现最真实的校园。
        </div>
      </div>

    </div>
    <Consult v-if="dialogVisible === true" @showClose="showClose" />
  </div>
</template>

<script setup>
import { defineEmits, ref } from 'vue'
const emits = defineEmits(['consultEmit'])
const consultSubmit = ref(null)
const consultShow = () => {
  emits('consultEmit')
}
const mouseOver = () => {
  consultSubmit.value.style.borderImage = 'linear-gradient(45deg, #31CDB0, #31CDB0, #31CDB0) 10 10'
}
const mouseLeave = () => {
  consultSubmit.value.style.borderImage = 'linear-gradient(45deg, #3551A4, #2C8EC4, #31CDB0) 10 10'
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 500px) {
  .sceneryContent {
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
  }

  .exhibitionContent {
    overflow: hidden;
    width: 1200px;
    margin: 0 auto;
  }

  .metaverseApplications {
    .exploitation {
      width: 100%;
      height: 960px;
      background: url('~@/assets/metaverse/useBanner.png') center no-repeat;
      // background-size: 100%;
      position: relative;

      &_title {
        width: 482px;
        margin: 0 auto;
        margin-top: 225px;
        height: 67px;
      }

      &_content {
        padding-top: 33px;
        font-size: 20px;
        font-weight: 300;
        color: #FFFFFF;
        line-height: 40px;
        opacity: 0.8;
      }

      &_consult {
        margin: 0 auto;
        margin-top: 100px;
        width: 200px;
        height: 70px;
        background: rgba(0, 255, 234, 0.1);
        border: 2px solid;
        border-image: linear-gradient(45deg, #3551A4, #2C8EC4, #31CDB0) 10 10;
        color: #43FBFF;
        font-size: 20px;
        line-height: 70px;
      }

      .pullDown {
        position: absolute;
        bottom: 116px;
        width: 100%;
        display: flex;
        justify-content: center;

        img {
          width: 56px;
          height: 40px;
        }
      }
    }

    .exhibition {
      width: 100%;
      height: 960px;
      background: url('~@/assets/metaverse/exhibition.png') center no-repeat;

      // background-size: 100%;


      &_title {
        padding-top: 230px;
        text-align: left;
        // padding-left: 380px;
        font-size: 56px;
      }

      &_content {
        text-align: left;
        font-size: 20px;
        // padding-left: 380px;
        padding-top: 10px;
      }
    }

    .scenery {
      width: 100%;
      height: 960px;
      background: url('~@/assets/metaverse/scenery.png') center no-repeat;

      // background-size: 100%;
      // display: flex;
      // justify-content: flex-end;
      &_title {
        padding-top: 198px;
        text-align: right;
        // padding-right: 360px;
        font-size: 56px;
      }

      &_content {
        padding-top: 39px;
        font-size: 20px;
        // padding-right: 360px;
        line-height: 36px;
        text-align: right;
      }
    }

    .world {
      width: 100%;
      height: 960px;
      background: url('~@/assets/metaverse/world.png') center no-repeat;
      // background-size: 100%;

      &_title {
        padding-top: 168px;
        text-align: left;
        font-size: 56px;
      }

      &_content {
        text-align: left;
        font-size: 20px;
        padding-top: 38px;
        line-height: 32px;
      }
    }

    .education {
      width: 100%;
      height: 960px;
      background: url('~@/assets/metaverse/education.png') center no-repeat;
      // background-size: 100%;

      // display: flex;
      // justify-content: flex-end;
      &_title {
        padding-top: 106px;
        text-align: right;
        font-size: 56px;
      }

      &_content {
        padding-top: 39px;
        font-size: 20px;
        text-align: right;
        line-height: 36px;
      }
    }
  }
}

@media screen and (min-width:359px) and (max-width: 500px) {
  .sceneryContent {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
  }

  .exhibitionContent {
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
  }

  .metaverseApplications {
    .exploitation {
      width: 100%;
      height: 350px;
      background: url('~@/assets/metaverse/useBanner.png') center no-repeat;
      background-size: 100%;
      position: relative;

      &_title {
        width: 55%;
        height: 30px;
        margin: 0 auto;
        margin-top: 50px;
        img {
          width: 100%;
          height: 100%;
        }
      }

      &_content {
        padding-top: 33px;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 16px;
        font-weight: 300;
        color: #FFFFFF;
        line-height: 30px;
        opacity: 0.8;
      }

      &_consult {
        margin: 0 auto;
        margin-top: 30px;
        font-size: 16px !important;
        width: 120px;
        height: 47px;
        background: rgba(0, 255, 234, 0.1);
        border: 2px solid;
        border-image: linear-gradient(45deg, #3551A4, #2C8EC4, #31CDB0) 10 10;
        color: #43FBFF;
        font-size: 20px;
        line-height: 47px;
      }

      .pullDown {
        position: absolute;
        bottom: 116px;
        width: 100%;
        display: flex;
        justify-content: center;

        img {
          width: 56px;
          height: 40px;
        }
      }
    }

    .exhibition {
      width: 100%;
      height: 200px;
      background: url('~@/assets/metaverse/exhibition.png') center no-repeat;

      background-size: 100%;


      &_title {
        text-align: center;
        // padding-left: 380px;
        font-size: 24px;
      }

      &_content {
        text-align: center;
        font-size: 16px;
        padding-top: 10px;
        padding-left: 10px;
        padding-right: 10px
      }
    }

    .scenery {
      width: 100%;
      height: 300px;
      background: url('~@/assets/metaverse/scenery.png') center no-repeat;

      background-size: 100%;
      // display: flex;
      // justify-content: flex-end;
      &_title {
        padding-top: 30px;
        text-align: center;
        // padding-right: 360px;
        font-size: 24px;
      }

      &_content {
        padding-top: 39px;
        font-size: 16px;
        // padding-right: 360px;
        line-height: 30px;
        text-align: center;
        padding-left: 20px;
        padding-right: 20px
      }
    }

    .world {
      width: 100%;
      height: 300px;
      background: url('~@/assets/metaverse/world.png') center no-repeat;
      background-size: 100%;

      &_title {
        padding-top: 30px;
        text-align: center;
        font-size: 24px;
      }

      &_content {
        text-align: center;
        font-size: 16px;
        padding-top: 38px;
        line-height: 30px;
        padding-left: 20px;
        padding-right: 20px
      }
    }

    .education {
      width: 100%;
      height: 300px;
      background: url('~@/assets/metaverse/education.png') center no-repeat;
      background-size: 100%;

      // display: flex;
      // justify-content: flex-end;
      &_title {
        padding-top: 30px;
        text-align: center;
        font-size: 24px;
      }

      &_content {
        padding-top: 39px;
        font-size: 16px;
        text-align: center;
        line-height: 30px;
        padding-left: 20px;
        padding-right: 20px
      }
    }
  }
}
</style>
