<template>
  <div class="videoContainer">
      <video class="fullscreenVideo" id="bgVid" muted="muted" autoplay="autoplay" width="100%" loop style="position: fixed;right: 0px;bottom: 0px;min-width: 100%;min-height: 100%;">
        <source src="../video/particle.mp4" type="video/mp4">
      </video>
  </div>
</template>

<script>
export default {
  name: ''
}
</script>

<style lang="scss" scoped>
  .videoContainer{
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -100;
}

.videoContainer:before{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  z-index: -1;
  top: 0;
  left: 0;
  background: rgba(25,29,34,.65);
  background-size: cover;
}

.fullscreenVideo{
  width: 100%;
  height: 100%;
  object-fit: fill
}
</style>
