<template>
  <div class="nftCollection">
    <video v-show="viewport > 500" :src="viveoSrc" class="fullscreenVideo" type="video/mp4" id="bgVid" loop="loop" muted="muted">
    </video>
    <div v-show="viewport <= 500" class="fullscreenVideo"></div>
    <!-- <video class="fullscreenVideo" id="bgVid" playsinline="" autoplay="autoplay" loop="loop" muted="muted">
      <source src="../../../video/particle.mp4" type="video/mp4">
    </video> -->
    <div class="collectionContent">
      <img class="wgt-fade-animate appear" src="@/assets/nftCollection/title@2x.png">
      <button
        class="wgt-fade-animate appear c-o"
        ref="consultSubmit"
        @click="consultShow"
        @mouseover="mouseOver"
        @mouseleave="mouseLeave"
      >我要咨询</button>
    </div>
    <div class="pullDown">
      <img src="~@/assets/pullDown.png">
    </div>
    <!-- <Consult v-if="dialogVisible === true" @showClose="showClose" /> -->
  </div>
</template>

<script setup>
import { onMounted, ref, reactive, defineEmits } from 'vue'
import { ElMessage } from 'element-plus'
import axios from 'axios'
import PubSub from "pubsub-js"
// import Consult from '@/components/consult.vue'
let viveoSrc = ref('')
let bgImg = new Image()
bgImg.src = require('@/assets/metaverse/tab.png')
bgImg.onload = () => {
  viveoSrc.value = require('../../../video/particle.mp4')
}


const emits = defineEmits(['consultEmit'])
const consultSubmit = ref(null)
// const dialogVisible = ref(false)
const consultShow = () => {
  emits('consultEmit')
  // dialogVisible.value = true
}
const mouseOver = () => {
  consultSubmit.value.style.borderImage = 'linear-gradient(45deg, #31CDB0, #31CDB0, #31CDB0) 10 10'
}
const mouseLeave = () => {
  consultSubmit.value.style.borderImage = 'linear-gradient(45deg, #3551A4, #2C8EC4, #31CDB0) 10 10'
}
// const showClose = () => {
//   dialogVisible.value = false
// }


const viewport = ref(window.innerWidth);
function innerWindow() {
  viewport.value = window.innerWidth;
  bgImg.src = require('@/assets/metaverse/tab.png')
  bgImg.onload = () => {
    viveoSrc.value = require('../../../video/particle.mp4')
  }
}
onMounted(() => {
  const bgVid = document.getElementById("bgVid")
  bgVid.autoplay = true;
  bgVid.load();
  window.addEventListener("resize", innerWindow);
})

</script>

<style lang="scss" scoped>
// .dialog-footer button:first-child {
//   margin-right: 10px;
// }
// ::v-deep .el-form-item--default .el-form-item__label {
//   font-size: 16px;
// }
// ::v-deep .el-input__inner {
//   font-size: 16px;
// }
// ::v-deep .el-select-dropdown__item {
//   font-size: 16px !important;
// }

@media screen and (min-width: 500px) {
  .nftCollection {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-height: 1036px;

    .collectionContent {
      padding-top: 285px;
      display: flex;
      align-items: center;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(18, 24, 43, 0.5);

      img {
        width: 922px;
      }

      button {
        margin-top: 143px;
        width: 200px;
        height: 70px;
        background: rgba(0, 255, 234, 0.1);
        border: 2px solid;
        border-image: linear-gradient(45deg, #3551A4, #2C8EC4, #31CDB0) 10 10;
        font-size: 20px;
        font-family: OPPOSans;
        font-weight: 400;
        color: #43FBFF;
      }
    }

    .fullscreenVideo {
      flex: 1;
      width: 100%;
      // height: 100%;
    }

    video {
      position: relative;
      // top: -100px;
      object-fit: cover;
      // object-position: top;
    }

    .pullDown {
      position: absolute;
      height: 450px;
      bottom: -120px;
      background: url('~@/assets/nftCollection/bottom.png') top no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      width: 100%;
      z-index: 999;
      // background: #000;

      img {
        position: absolute;
        top: 130px;
        width: 56px;
        height: 40px;
      }
    }
  }
}

@media screen and (min-width:359px) and (max-width: 500px) {
  .nftCollection {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 500px;

    .collectionContent {
      padding-top: 120px;
      display: flex;
      align-items: center;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(18, 24, 43, 0.5);
      img {
        width: 90%;
      }
      button {
        margin-top: 50px;
        width: 120px;
        background: rgba(0, 255, 234, 0.1);
        border: 1px solid;
        border-image: linear-gradient(45deg, #3551A4, #2C8EC4, #31CDB0) 10 10;
        font-size: 16px;
        font-family: OPPOSans;
        font-weight: 400;
        color: #43FBFF;
      }
    }

    .fullscreenVideo {
      flex: 1;
      width: 100%;
      // height: 100%;
      background: url('../../../assets/video.png') no-repeat;
      background-size: 100% 100%;
    }

    video {
      position: relative;
      // top: -100px;
      object-fit: cover;
      // object-position: top;
    }

    .pullDown {
      position: absolute;
      height: 200px;
      bottom: -50px;
      background: url('~@/assets/nftCollection/bottom.png') top no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      width: 100%;
      z-index: 999;
      // background: #000;

      img {
        position: absolute;
        top: 20px;
        width: 36px;
        height: 26px;
      }
    }
  }
}
</style>
