import { createStore } from 'vuex'

export default createStore({
  state: {
    viewport: 0,
    stateLocale: 'English',
    hideClose: 0
  },
  mutations: {
    setViewport (state, action) {
      state.viewport = action
    },
    setStateLocale (state, action) {
      state.stateLocale = action
    },
    setHideClose (state, action) {
      state.hideClose = action + 1
    }
  },
  actions: {
  },
  modules: {
  }
})
