<template>
  <div id="webHeader" class="header" :style="style" :class="{ color: color }">
    <div class="headContent flex_justify_center">
      <img class="logo c-o" src="~@/assets/logo.png" alt="">
      <!-- <div class="nav_list display_flex">
        <div class="nav_list_item" v-for="item in navList" :key="item.id" :class="navTab === item.id ? 'active' : ''"
          @click="navSelect(item.id)">
          {{ item.text }}
          <img v-if="navTab === item.id" class="nav_bottom" src="~@/assets/headerActive.png" alt="" />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import useScrollTop from "@/hook/useScrollTop.js";
const navTab = ref(1)
const navList = ref([
  {
    id: 1,
    text: '主页'
  },
  // {
  //   id: 2,
  //   text: '案例'
  // },
  // {
  //   id: 3,
  //   text: '关于我们'
  // }
])
const navSelect = (type) => {
  navTab.value = type
}
const color = ref(false);
const style = reactive({
  backgroundColor: ""
});
useScrollTop(color.value, style); // 滚动条变色
</script>

<style lang="scss" scoped>
@media screen and (min-width: 500px) {
  .headContent {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    height: 95px;
  }
  .header {
    position: fixed;
    z-index: 300;
    top: 0px;
    width: 100%;
    .logo {
      position: absolute;
      top: 18px;
      left: 0px;
      width: 142px;
      height: 50px;
    }
    .nav_list {
      padding-top: 20px;
      &_item {
        cursor: pointer;
        // padding: 0px 80px;
        width: 200px;
        font-size: 20px;
        font-weight: 500;
        color: rgb(202, 202, 202);
        .nav_bottom {
          width: 200px;
          height: 30px;
        }
      }
      .active {
        color: #fff;
      }
    }
    .pitch {
      display: flex;
    }
  }
}
@media screen and (min-width:359px) and (max-width: 500px) {
  .headContent {
    position: relative;
    width: 100%;
    margin: 0 auto;
    height: 65px;
    display: flex;
    justify-content: flex-end;
    padding: 0px 10px;
  }
  .header {
    position: fixed;
    z-index: 9999;
    top: 0px;
    width: 100%;
    .logo {
      position: absolute;
      top: 10px;
      left: 10px;
      width: 100px;
      height: 30px;
    }
    .nav_list {
      &_item {
        // padding: 0px 80px;
        width: 80px;
        font-size: 16px;
        font-weight: 500;
        color: rgb(202, 202, 202);
        .nav_bottom {
          width: 160px;
          height: 10px;
        }
      }
      .active {
        color: #fff;
      }
    }
    .pitch {
      display: flex;
    }
  }
}
</style>
