export function showDiv(idName, className) {
  const showId = document.getElementById(idName)
  const clients = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
  const divTop = showId.getBoundingClientRect().top
  if (divTop <= clients - 250) {
    showId.classList.add(className)
  } else {
    // showId.classList.remove(className)
  }
}

let observer = new IntersectionObserver((entries) => {
  entries.forEach(
    (entry) => {
      if (entry.isIntersecting) {
        console.log(entry, entries)
      } else {
        console.log('离开')
      }
    },
    { threshold: [0.2, 0.25, 0.5, 1] }
  )
})
export function elementShow(element, animation) {}
