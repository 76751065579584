<template>
  <div id="nav">
      <router-view />
  </div>
</template>
<script setup>
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
}

@media screen and (min-width: 500px) {
  ::-webkit-scrollbar {
  }
}
@media screen and (min-width:359px) and (max-width: 500px) {
  ::-webkit-scrollbar {
    width: 2px;
  }
}
#nav {
  a {
    font-weight: bold;
    color: #fff;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
