<template>
  <div class="web3" id="web3">
    <div class="web3Content">
      <div class="title">
        <img src="@/assets/web3.0/title@2x.png" />
      </div>
      <div class="collectionBtn">
        <button>数据资产</button>
        <button>数据确权</button>
        <button>数据安全</button>
      </div>
      <div class="content">
        <div class="Segmentum">
          <div class="ball ballOne" @click="dianji">
            <div class="starTrails starTrailsOne">
              <div class="asteroid asteroidOne" :style="{ ...asteroidBgStyle('NFT1/1-1.png') }">
                <span class="label">游戏</span>
              </div>
              <div class="asteroid asteroidTwo" :style="{ ...asteroidBgStyle('NFT1/1-2.png') }">
                <span class="label">NFT版权</span>
              </div>
              <div class="asteroid asteroidThree" :style="{ ...asteroidBgStyle('NFT1/1-3.png') }">
                <span class="label">NFT价值赋能</span>
              </div>
            </div>
          </div>
          <div class="ball ballTwo">
            <div class="starTrails">
              <div class="asteroid asteroidOne">
                <span class="label">数字孪生</span>
              </div>
              <div class="asteroid asteroidTwo">
                <span class="label">元宇宙</span>
              </div>
              <div class="asteroid asteroidThree">
                <span class="label">虚拟现实</span>
              </div>
            </div>
          </div>
          <div class="ball ballThree">
            <div class="starTrails starTrailsThree">
              <div class="asteroid asteroidOne" :style="{ ...asteroidBgStyle('NFT3/3-1.png') }">
                <span class="label">商场</span>
              </div>
              <div class="asteroid asteroidTwo" :style="{ ...asteroidBgStyle('NFT3/3-2.png') }">
                <span class="label">藏品</span>
              </div>
              <div class="asteroid asteroidThree" :style="{ ...asteroidBgStyle('NFT3/3-3.png') }">
                <span class="label">钱包</span>
              </div>
            </div>
          </div>
          <div class="ball ballFour">
            <div class="starTrails starTrailsFour">
              <div class="asteroid asteroidOne" :style="{ ...asteroidBgStyle('NFT4/4-1.png') }">
                <span class="label">游戏</span>
              </div>
              <div class="asteroid asteroidTwo" :style="{ ...asteroidBgStyle('NFT4/4-2.png') }">
                <span class="label">旅游</span>
              </div>
              <div class="asteroid asteroidThree" :style="{ ...asteroidBgStyle('NFT4/4-3.png') }">
                <span class="label">社交</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pullDown o-p-3">
      <img src="~@/assets/pullDown.png">
    </div>
  </div>
</template>

<script setup>
import { reactive } from 'vue'

function asteroidBgStyle(url) {
  return {
    background: `url(${require('@/assets/web3.0/' + url)}) center no-repeat`,
    backgroundSize: '100% 100%'
  }
}
let dianji = () => {
  console.log(1111)
}
</script>

<style lang="scss" scoped>
@keyframes rorate-Segmentum {
  0% {
    transform: rotate(-10deg) scaleY(0.5) rotate(0);
  }

  100% {
    transform: rotate(-10deg) scaleY(0.5) rotate(360deg);
  }
}

@keyframes rorate-ballOne {
  0% {
    transform: rotate(0) scaleY(1) scaleX(0.5) rotate(-25deg);
  }

  50% {
    transform: rotate(-180deg) scaleY(4) scaleX(2) rotate(-25deg);
  }

  100% {
    transform: rotate(-360deg) scaleY(1) scaleX(0.5) rotate(-25deg);
  }
}

@keyframes rorate-ballTwo {
  0% {
    transform: rotate(0) scaleY(4) scaleX(2) rotate(0deg);
  }

  50% {
    transform: rotate(-180deg) scaleY(1) scaleX(0.5) rotate(0deg);
  }

  100% {
    transform: rotate(-360deg) scaleY(4) scaleX(2) rotate(0deg);
  }
}

@keyframes rorate-ballThree {
  0% {
    transform: rotate(0) scaleY(2.5) scaleX(1.25) rotate(45deg);
  }

  25% {
    transform: rotate(-90deg) scaleY(1) scaleX(0.5) rotate(45deg);
  }

  50% {
    transform: rotate(-180deg) scaleY(2.5) scaleX(1.25) rotate(45deg);
  }

  75% {
    transform: rotate(-270deg) scaleY(4) scaleX(2) rotate(45deg);
  }

  100% {
    transform: rotate(-360deg) scaleY(2.5) scaleX(1.25) rotate(45deg);
  }
}

@keyframes rorate-ballFour {
  0% {
    transform: rotate(0) scaleY(2.5) scaleX(1.25) rotate(45deg);
  }

  25% {
    transform: rotate(-90deg) scaleY(4) scaleX(2) rotate(45deg);
  }

  50% {
    transform: rotate(-180deg) scaleY(2.5) scaleX(1.25) rotate(45deg);
  }

  75% {
    transform: rotate(-270deg) scaleY(1) scaleX(0.5) rotate(45deg);
  }

  100% {
    transform: rotate(-360deg) scaleY(2.5) scaleX(1.25) rotate(45deg);
  }
}

@keyframes rorate-starTrails {
  0% {
    transform: translate(-50%, -50%) scaleY(0.5) rotate(0);
  }

  100% {
    transform: translate(-50%, -50%) scaleY(0.5) rotate(360deg);
  }
}

@keyframes rorate-asteroidOne {
  0% {
    transform: rotate(0) scaleY(1) scaleX(0.5);
  }

  50% {
    transform: rotate(-180deg) scaleY(1) scaleX(0.5);
  }

  100% {
    transform: rotate(-360deg) scaleY(1) scaleX(0.5);
  }
}

@keyframes rorate-asteroidTwo {
  0% {
    transform: rotate(0) scaleY(1) scaleX(0.5);
  }

  50% {
    transform: rotate(-180deg) scaleY(1) scaleX(0.5);
  }

  100% {
    transform: rotate(-360deg) scaleY(1) scaleX(0.5);
  }
}

@media screen and (min-width: 500px) {
  .web3 {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    opacity: 0;

    .web3Content {
      height: 1036px;

      .title {
        width: 100%;
        display: flex;
        justify-content: center;

        img {
          width: 490px;
          height: 59px;
        }

      }

      .collectionBtn {
        margin-top: 40px;
        display: flex;
        justify-content: center;

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 120px;
          height: 40px;
          background: url('~@/assets/nftCollection/border@2x.png') center no-repeat;
          background-size: 100%;
          font-size: 18px;
          font-family: 'OPPOSans';
          font-weight: bold;
          color: #39D8E5;

          &:nth-child(2) {
            margin: 0 20px;
          }
        }
      }

      .content {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        flex: 1;
        background: url('~@/assets/web3.0/bigBall@2x.png') center no-repeat;
        // background-size: 65% 100;

        &::after {
          content: '数字藏品';
          position: absolute;
          top: 50%;
          left: 51%;
          transform: translate(-50%, -50%);
          font-size: 56px;
          font-family: 'HarmonyOS Sans SC';
          font-weight: 500;
          color: #FFFFFF;
          text-shadow: 0px 2px 0px #0C0D13;
        }

        &::before {
          content: '';
          position: absolute;
          top: 55%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(-10deg) scaleY(0.5);
          border-radius: 50%;
          width: 1200px;
          height: 1200px;
          border: 2px solid #525567;

        }

        .Segmentum {
          position: relative;
          width: 900px;
          height: 900px;
          color: #fff;
          border: 2px solid #525567;
          border-radius: 50%;
          transform: rotate(-10deg) scaleY(0.5) rotate(0);
          animation: rorate-Segmentum 20s linear infinite;

          .ball {
            position: absolute;
            width: 100px;
            height: 100px;
            border-radius: 50%;
          }

          .ballOne {
            top: -50px;
            left: 375px;
            background: url('~@/assets/web3.0/NFT1/NFT1.0.png') center no-repeat;
            background-size: 100% 100%;
            transform: rotate(0) scaleY(1) scaleX(0.5) rotate(-25deg);
            animation: rorate-ballOne 20s linear infinite;

            .starTrailsOne {
              transform: translate(-50%, -50%) rotate(0deg) scaleY(0.25);
            }

            &::after {
              content: '';
              position: absolute;
              top: 0PX;
              left: 0PX;
              width: 100px;
              height: 50px;
              border-radius: 55PX 55PX 0 0;
              // transform: rotate(-50deg);
              transform-origin: 50px 50px;
              background: url('~@/assets/web3.0/NFT1/NFT1.0.png') top right no-repeat;
              // backdrop-filter: 10px;
              background-size: 100%;
              z-index: 99;
            }

            &::before {
              content: 'NFT 2.0';
              z-index: 999;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) rotate(35deg);
              color: #fff;
            }
          }

          .ballTwo {
            top: 850px;
            left: 400px;
            background: url('~@/assets/web3.0/NFT2/NFT2.0.png') center no-repeat;
            background-size: 100% 100%;
            transform: rotate(0) scaleY(3) scaleX(1.5) rotate(0deg);
            animation: rorate-ballTwo 20s linear infinite;

            &::after {
              content: '';
              position: absolute;
              top: 0PX;
              left: 0PX;
              width: 100px;
              height: 50px;
              border-radius: 55PX 55PX 0 0;
              transform: rotate(0deg);
              transform-origin: 50px 50px;
              background: url('~@/assets/web3.0/NFT2/NFT2.0.png') top left no-repeat;
              background-size: 100%;
              z-index: 99;
            }

            &::before {
              content: 'NFT 4.0';
              z-index: 999;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) rotate(10deg);
              color: #fff;
            }

            .label {
              transform: rotate(10deg) !important;
            }
          }

          .ballThree {
            top: 400px;
            left: -50px;
            background: url('~@/assets/web3.0/NFT3/NFT3.0.png') center no-repeat;
            background-size: 100% 100%;
            transform: rotate(0) scaleY(3) scaleX(1.5) rotate(45deg);

            animation: rorate-ballThree 20s linear infinite;

            .starTrailsThree {
              transform: translate(-50%, -50%) rotate(0deg) scaleY(0.25);
            }

            &::after {
              content: '';
              position: absolute;
              top: 0PX;
              left: 0PX;
              width: 100px;
              height: 50px;
              border-radius: 55PX 55PX 0 0;
              transform: rotate(0deg);
              transform-origin: 50px 50px;
              background: url('~@/assets/web3.0/NFT3/NFT3.0.png') top right no-repeat;
              background-size: 100%;
              z-index: 99;
            }

            &::before {
              content: 'NFT 1.0';
              z-index: 999;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) rotate(-35deg);
              color: #fff;
            }

            .label {
              transform: rotate(-35deg) !important;
            }
          }

          .ballFour {
            top: 400px;
            left: 850px;
            background: url('~@/assets/web3.0/NFT4/NFT4.0.png') center no-repeat;
            background-size: 100% 100%;
            transform: rotate(0) scaleY(3) scaleX(1.5) rotate(45deg);

            animation: rorate-ballFour 20s linear infinite;

            .starTrailsThree {
              transform: translate(-50%, -50%) rotate(0deg) scaleY(0.25);
            }

            &::after {
              content: '';
              position: absolute;
              top: 0PX;
              left: 0PX;
              width: 100px;
              height: 50px;
              border-radius: 55PX 55PX 0 0;
              transform-origin: 50px 50px;
              background: url('~@/assets/web3.0/NFT4/NFT4.0.png') top right no-repeat;
              background-size: 100%;
              z-index: 99;
            }

            &::before {
              content: 'NFT 3.0';
              z-index: 999;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) rotate(-35deg);
              color: #fff;
            }

            .label {
              transform: rotate(-35deg) !important;
            }
          }
        }

        .starTrails {
          position: absolute;
          top: 50%;
          left: 50%;
          border-radius: 50%;
          width: 150px;
          height: 150px;
          transform: translate(-50%, -50%) rotate(0deg) scaleY(0.25);
          border: 1px solid #dcdff3;
          animation: rorate-starTrails 10s linear infinite;

          .asteroid {
            position: absolute;
            width: 50px;
            height: 50px;
            border-radius: 50%;

            .label {
              text-align: center;
              width: 100px;
              position: absolute;
              bottom: -25px;
              left: -15px;
              color: #9F9F9F;
              font-size: 14px;
              font-family: 'HarmonyOS Sans SC';
              transform: rotate(35deg);
            }
          }

          .asteroidOne {
            top: -25px;
            left: 50px;
            transform: scaleY(2) scaleX(0.5) rotate(45deg);
            background: url('~@/assets/web3.0/NFT2/2-1.png') center no-repeat;
            background-size: 100% 100%;
            animation: rorate-asteroidOne 10s linear infinite;
          }

          .asteroidTwo {
            top: 125px;
            left: 50px;
            transform: scaleY(2) scaleX(0.5) rotate(45deg);
            background: url('~@/assets/web3.0/NFT2/2-2.png') center no-repeat;
            background-size: 100% 100%;
            animation: rorate-asteroidTwo 10s linear infinite;
          }

          .asteroidThree {
            top: 50px;
            left: 125px;
            transform: scaleY(2) scaleX(0.5) rotate(45deg);
            background: url('~@/assets/web3.0/NFT2/2-3.png') center no-repeat;
            background-size: 100% 100%;
            animation: rorate-asteroidTwo 10s linear infinite;
          }
        }
      }
    }

  }

  .pullDown {
    position: absolute;
    bottom: -100px;
    width: 100%;
    display: flex;
    justify-content: center;

    img {
      width: 56px;
      height: 40px;
    }
  }
}

@media screen and (min-width:359px) and (max-width: 500px) {
  .web3 {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    opacity: 0;

    .web3Content {
      height: 100%;

      .title {
        padding-top: 50px;
        width: 100%;
        display: flex;
        justify-content: center;

        img {
          width: 70%;
          height: 35px;
        }

      }

      .collectionBtn {
        margin-top: 40px;
        display: flex;
        justify-content: center;

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 90px;
          height: 30px;
          background: url('~@/assets/nftCollection/border@2x.png') center no-repeat;
          background-size: 100%;
          font-size: 12px;
          font-family: 'OPPOSans';
          font-weight: bold;
          color: #39D8E5;

          &:nth-child(2) {
            margin: 0 20px;
          }
        }
      }

      .content {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        flex: 1;
        background: url('~@/assets/web3.0/bigBall@2x.png') center no-repeat;
        background-size: 100%;

        &::after {
          content: '数字藏品';
          position: absolute;
          top: 50%;
          left: 51%;
          transform: translate(-50%, -50%);
          font-size: 16px;
          font-family: 'HarmonyOS Sans SC';
          font-weight: 500;
          color: #FFFFFF;
          text-shadow: 0px 2px 0px #0C0D13;
        }

        &::before {
          content: '';
          position: absolute;
          top: 55%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(-10deg) scaleY(0.5);
          border-radius: 50%;
          width: 400px;
          height: 400px;
          border: 2px solid #525567;

        }

        .Segmentum {
          position: relative;
          width: 300px;
          height: 300px;
          color: #fff;
          border: 2px solid #525567;
          border-radius: 50%;
          transform: rotate(-10deg) scaleY(0.5) rotate(0);
          animation: rorate-Segmentum 20s linear infinite;

          .ball {
            position: absolute;
            width: 50px;
            height: 50px;
            border-radius: 50%;
          }

          .ballOne {
            top: -15px;
            left: 80px;
            background: url('~@/assets/web3.0/NFT1/NFT1.0.png') center no-repeat;
            background-size: 100% 100%;
            transform: rotate(0) scaleY(1) scaleX(0.5) rotate(-25deg);
            animation: rorate-ballOne 20s linear infinite;

            .starTrailsOne {
              transform: translate(-50%, -50%) rotate(0deg) scaleY(0.25);
            }

            &::after {
              content: '';
              position: absolute;
              top: 0PX;
              left: 0PX;
              width: 50px;
              height: 25px;
              border-radius: 55PX 55PX 0 0;
              // transform: rotate(-50deg);
              transform-origin: 25px 25px;
              background: url('~@/assets/web3.0/NFT1/NFT1.0.png') top right no-repeat;
              // backdrop-filter: 10px;
              background-size: 100%;
              z-index: 99;
            }

            &::before {
              content: 'NFT 2.0';
              font-size: 14px;
              z-index: 999;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) rotate(35deg);
              color: #fff;
            }
          }

          .ballTwo {
            top: 270px;
            left: 170px;
            background: url('~@/assets/web3.0/NFT2/NFT2.0.png') center no-repeat;
            background-size: 100% 100%;
            transform: rotate(0) scaleY(3) scaleX(1.5) rotate(0deg);
            animation: rorate-ballTwo 20s linear infinite;

            &::after {
              content: '';
              position: absolute;
              top: 0PX;
              left: 0PX;
              width: 50px;
              height: 25px;
              border-radius: 55PX 55PX 0 0;
              transform: rotate(0deg);
              transform-origin: 50px 50px;
              background: url('~@/assets/web3.0/NFT2/NFT2.0.png') top left no-repeat;
              background-size: 100%;
              z-index: 99;
            }

            &::before {
              content: 'NFT 4.0';
              z-index: 999;
              position: absolute;
              top: 50%;
              left: 50%;
              font-size: 14px;
              transform: translate(-50%, -50%) rotate(10deg);
              color: #fff;
            }

            .label {
              margin-left: -20px;
              transform: rotate(10deg) !important;
            }
          }

          .ballThree {
            top: 175px;
            left: -25px;
            background: url('~@/assets/web3.0/NFT3/NFT3.0.png') center no-repeat;
            background-size: 100% 100%;
            transform: rotate(0) scaleY(3) scaleX(1.5) rotate(45deg);

            animation: rorate-ballThree 20s linear infinite;

            .starTrailsThree {
              transform: translate(-50%, -50%) rotate(0deg) scaleY(0.25);
            }

            &::after {
              content: '';
              position: absolute;
              top: 0PX;
              left: 0PX;
              width: 50px;
              height: 25px;
              border-radius: 55PX 55PX 0 0;
              transform: rotate(0deg);
              transform-origin: 50px 50px;
              background: url('~@/assets/web3.0/NFT3/NFT3.0.png') top right no-repeat;
              background-size: 100%;
              z-index: 99;
            }

            &::before {
              content: 'NFT 1.0';
              z-index: 999;
              font-size: 14px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) rotate(-35deg);
              color: #fff;
            }

            .label {
              transform: rotate(-35deg) !important;
            }
          }

          .ballFour {
            top: 70px;
            left: 265px;
            background: url('~@/assets/web3.0/NFT4/NFT4.0.png') center no-repeat;
            background-size: 100% 100%;
            transform: rotate(0) scaleY(3) scaleX(1.5) rotate(45deg);

            animation: rorate-ballFour 20s linear infinite;

            .starTrailsThree {
              transform: translate(-50%, -50%) rotate(0deg) scaleY(0.25);
            }

            &::after {
              content: '';
              position: absolute;
              top: 0PX;
              left: 0PX;
              width: 50px;
              height: 25px;
              border-radius: 55PX 55PX 0 0;
              transform-origin: 50px 50px;
              background: url('~@/assets/web3.0/NFT4/NFT4.0.png') top right no-repeat;
              background-size: 100%;
              z-index: 99;
            }

            &::before {
              content: 'NFT 3.0';
              z-index: 999;
              position: absolute;
              top: 50%;
              left: 50%;
              font-size: 14px;
              transform: translate(-50%, -50%) rotate(-35deg);
              color: #fff;
            }

            .label {
              transform: rotate(-35deg) !important;
            }
          }
        }

        .starTrails {
          position: absolute;
          top: 50%;
          left: 50%;
          border-radius: 50%;
          width: 75px;
          height: 75px;
          transform: translate(-50%, -50%) rotate(0deg) scaleY(0.25);
          border: 1px solid #dcdff3;
          animation: rorate-starTrails 10s linear infinite;

          .asteroid {
            position: absolute;
            width: 50px;
            height: 50px;
            border-radius: 50%;

            .label {
              text-align: center;
              width: 100px;
              position: absolute;
              bottom: 5px;
              left: -40px;
              color: #9F9F9F;
              font-size: 12px;
              font-family: 'HarmonyOS Sans SC';
              transform: rotate(35deg);
            }
          }

          .asteroidOne {
            top: -25px;
            left: 10px;
            transform: scaleY(2) scaleX(0.5) rotate(45deg);
            background: url('~@/assets/web3.0/NFT2/2-1.png') center no-repeat;
            background-size: 100% 100%;
            animation: rorate-asteroidOne 10s linear infinite;
          }

          .asteroidTwo {
            top: 45px;
            left: 30px;
            transform: scaleY(2) scaleX(0.5) rotate(45deg);
            background: url('~@/assets/web3.0/NFT2/2-2.png') center no-repeat;
            background-size: 100% 100%;
            animation: rorate-asteroidTwo 10s linear infinite;
          }

          .asteroidThree {
            top: 20px;
            left: -25px;
            transform: scaleY(2) scaleX(0.5) rotate(45deg);
            background: url('~@/assets/web3.0/NFT2/2-3.png') center no-repeat;
            background-size: 100% 100%;
            animation: rorate-asteroidTwo 10s linear infinite;
          }
        }
      }
    }

  }

  .pullDown {
    position: absolute;
    bottom: -100px;
    width: 100%;
    display: flex;
    justify-content: center;

    img {
      width: 36px;
      height: 26px;
    }
  }
}
</style>
