<template>
  <div class="metaverse" id="metaverse">
    <div class="metaverse_tab">
      <div class="metaverContent">
        <div class="tab_center">
          <div class="tab_center_title"></div>
          <div class="tab_center_border display_flex">
            <div class="tab_center_border_item">可读可写</div>
            <div class="tab_center_border_item m-l">数据归属自己</div>
            <div class="tab_center_border_item m-l">虚拟现实</div>
          </div>
          <div class="tab_cut">
            <div ref="identity" class="tab_cut_item flex_justify_around" :class="{
             active1: tab == 1
            }" @click="cutActive(1)">
              元宇宙数字身份
            </div>
            <div ref="use" class="tab_cut_item flex_justify_around m-l" @click="cutActive(2)" :class="{
             active2: tab == 2
            }">
              元宇宙应用
            </div>
            <div ref="game" class="tab_cut_item flex_justify_around m-ls" @click="cutActive(3)" :class="{
             active3: tab == 3
            }">
              元宇宙游戏
            </div>
          </div>
        </div>
      </div>
      <div class="pullDown o-p-3">
        <img src="~@/assets/pullDown.png" alt="">
      </div>
    </div>
    <!-- 元宇宙应用 -->
    <MetaverseApplications v-if="tab === 2" @consultEmit="consultEmit" />
    <DigitalIdentity v-if="tab === 1" @consultEmit="consultEmit" />
    <MetaverseGame v-if="tab === 3" @consultEmit="consultEmit" />
  </div>
</template>
<script setup>
import { ref, onMounted, defineEmits } from 'vue'
import MetaverseApplications from './metaverseApplications/index.vue'
import DigitalIdentity from './digitalIdentity/index.vue'
import MetaverseGame from './MetaverseGame/index.vue'

const emits = defineEmits(['consultEmit'])
const tab = ref(1)
const cutActive = (type) => {
  tab.value = type
}
const consultEmit = () => {
  emits('consultEmit')
}

</script>

<style lang="scss" scoped>
@media screen and (min-width: 500px) {
  .metaverse {
    width: 100%;
    height: 100%;
    opacity: 0;

    .metaverse_tab {
      overflow: hidden;
      width: 100%;
      height: 960px;
      background: url('~@/assets/metaverse/tab.png') center no-repeat;
      // background-size: 100%;
      position: relative;

      .metaverContent {
        width: 1200px;
        margin: 200px auto 0;

        .tab_center {

          &_title {
            width: 574px;
            height: 59px;
            background: url('~@/assets/metaverse/title.png') center no-repeat;
            // background-size: 100%;
          }

          &_border {
            margin-top: 40px;

            &_item {
              width: 118px;
              height: 39px;
              background: url('~@/assets/metaverse/border.png') center no-repeat;
              background-size: 100%;
              color: #39D8E5;
              font-size: 18px;
              line-height: 39px;
            }

            .m-l {
              margin-left: 30px;
            }
          }

          .tab_cut {
            cursor: pointer;
            margin-top: 114px;
            display: flex;
            align-items: center;

            &_item {
              width: 239px;
              height: 139px;
              background: url('~@/assets/metaverse/tabCut/cut1.png') center no-repeat;
              background-size: 83.68%;
              font-size: 24px;
              transition: all 0.1s;
            }

            .m-l {
              margin-left: 30px;
              background: url('~@/assets/metaverse/tabCut/cut2.png') center no-repeat;
              background-size: 83.68%;
            }

            .m-ls {
              margin-left: 30px;
              background: url('~@/assets/metaverse/tabCut/cut3.png') center no-repeat;
              background-size: 83.68%;
            }

            .active1 {
              background: url('~@/assets/metaverse/tabCut/active1.png') center no-repeat;
              background-size: 100%;
            }

            .active2 {
              background: url('~@/assets/metaverse/tabCut/active2.png') center no-repeat;
              background-size: 100%;
            }

            .active3 {
              background: url('~@/assets/metaverse/tabCut/active3.png') center no-repeat;
              background-size: 100%;
            }
          }
        }

      }

      .pullDown {
        position: absolute;
        bottom: 50px;
        width: 100%;
        display: flex;
        justify-content: center;

        img {
          width: 56px;
          height: 40px;
        }
      }
    }
  }
}

@media screen and (min-width:359px) and (max-width: 500px) {
  .metaverse {
    width: 100%;
    height: 100%;
    opacity: 0;

    .metaverse_tab {
      overflow: hidden;
      width: 100%;
      height: 660px;
      background: url('~@/assets/metaverse/tab.png') center no-repeat;
      background-size: 100%;
      position: relative;

      .metaverContent {
        width: 100%;
        margin: 180px auto 0;

        .tab_center {

          &_title {
            margin-left: 15%;
            width: 70%;
            height: 35px;
            background: url('~@/assets/metaverse/title.png') center no-repeat;
            background-size: 100%;
          }

          &_border {
            display: flex;
            justify-content: center;
            margin-top: 40px;

            &_item {
              width: 90px;
              height: 30px;
              background: url('~@/assets/metaverse/border.png') center no-repeat;
              background-size: 100%;
              color: #39D8E5;
              font-size: 12px;
              line-height: 30px;
            }

            .m-l {
              margin-left: 30px;
            }
          }

          .tab_cut {
            margin-top: 100px;
            display: flex;
            justify-content: center;
            align-items: center;

            &_item {
              width: 30%;
              height: 100px;
              background: url('~@/assets/metaverse/tabCut/cut1.png') center no-repeat;
              background-size: 83.68%;
              font-size: 14px;
              transition: all 0.1s;
            }

            .m-l {
              background: url('~@/assets/metaverse/tabCut/cut2.png') center no-repeat;
              background-size: 83.68%;
            }

            .m-ls {
              background: url('~@/assets/metaverse/tabCut/cut3.png') center no-repeat;
              background-size: 83.68%;
            }

            .active1 {
              background: url('~@/assets/metaverse/tabCut/active1.png') center no-repeat;
              background-size: 100%;
            }

            .active2 {
              background: url('~@/assets/metaverse/tabCut/active2.png') center no-repeat;
              background-size: 100%;
            }

            .active3 {
              background: url('~@/assets/metaverse/tabCut/active3.png') center no-repeat;
              background-size: 100%;
            }
          }
        }

      }

      .pullDown {
        position: absolute;
        bottom: 80px;
        width: 100%;
        display: flex;
        justify-content: center;

        img {
          width: 36px;
          height: 26px;
        }
      }
    }
  }
}
</style>
