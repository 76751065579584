<template>
  <div v-if="viewport <= 500" style="height: 80px;"></div>
  <!-- wgt-fade-animate appear -->
  <div :class="{
    'main': viewport > 500,
    'main wgt-fade-animate appear': viewport <= 500
  }" id="webTransition">
    <div class="webTransition">
      <div class="web1">
        <img src="~@/assets/nftCollection/web1@2x.png">
        <div class="label">
          <span>阅读式网络</span>
          <span>内容不可修改</span>
        </div>
        <div v-if="viewport > 500" class="linkBall">
          <div class="ballOne">
            <div class="ball" ref="son1" :style="{
              top:ballOneTop+'px',
              left:ballOneleft + 'px'
            }">
              <span>政务</span>
              <span>系统</span>
            </div>
            <div class="line">
              <svg height="500">
                <line :x1="75 + ballOneleft" :y1="40 + ballOneTop" :x2="165 + ballTwoleft" :y2="120 + ballTwoTop"
                  style="stroke: #21C5CE;opacity: 0.5; stroke-width: 1" />
              </svg>
            </div>
          </div>
          <div class="ballTwo">
            <div class="ball" :style="{
              top:ballTwoTop+'px',
              left:ballTwoleft + 'px'
            }">
              <span>新闻</span>
              <span>网站</span>
            </div>
            <div class="line lineTow">
              <svg height="300">
                <line :x1="75 + ballTwoleft" :y1="140 + ballTwoTop" x2="170" :y2="40 + ballThreeTop"
                  style="stroke: #21C5CE;opacity: 0.5; stroke-width: 1" />
              </svg>
            </div>
          </div>
          <div class="ballThree">
            <div class="ball" :style="{
              top:ballThreeTop + 'px'
            }">
              <span>舆情</span>
              <span>监控</span>
            </div>
          </div>
        </div>
        <div v-else class="static_label">
          <div class="static_label_item">政务<br />系统</div>
          <div class="static_label_line"></div>
          <div class="static_label_item s-left">新闻<br />网站</div>
          <div class="static_label_line2"></div>
          <div class="static_label_item s-top">舆情<br />监控</div>
        </div>
      </div>
      <div class="bisect"></div>
      <div class="web2">
        <img class="img-left" src="~@/assets/nftCollection/web2@2x.png">
        <div class="label">
          <span>可创造可分享</span>
          <span>交互性</span>
        </div>
        <div v-if="viewport > 500" class="linkBall">
          <div class="ballOne">
            <div class="ball" :style="{
              top:ballTwoTop+'px',
              left:ballTwoleft + 'px'
            }">
              <span>社交</span>
              <span>应用</span>
            </div>
            <div class="line lineTow">
              <svg height="300">
                <line :x1="75 + ballTwoleft" :y1="140 + ballTwoTop" :x2="165 + ballOneleft" :y2="70 + ballOneTop"
                  style="stroke: #21C5CE;opacity: 0.5; stroke-width: 1" />
              </svg>
            </div>
          </div>
          <div class="ballTwo">
            <div class="ball" ref="son1" :style="{
              top:ballOneTop+'px',
              left:ballOneleft + 'px'
            }">
              <span>论坛</span>
            </div>
            <div class="line">
              <svg height="500">
                <line :x1="75 + ballOneleft" :y1="40 + ballOneTop" x2="170" :y2="170 + ballFourTop"
                  style="stroke: #21C5CE;opacity: 0.5; stroke-width: 1" />
              </svg>
            </div>
          </div>
          <div class="ballThree">
            <div class="ball" :style="{
              top:ballFourTop + 'px'
            }">
              <span>游戏</span>
            </div>
          </div>
        </div>
        <div v-else class="static_label right-200" style="margin-top: -300px;">
          <div class="static_label_item s-right">社交<br />应用</div>
          <div class="static_label_line line-right"></div>
          <div class="static_label_item s-t">论坛</div>
          <div class="static_label_line"></div>
          <div class="static_label_item s-right s-t">游戏</div>
        </div>
      </div>
      <div class="bridge">
        <img src="~@/assets/nftCollection/bridge@2x.png">
        <div class="circle">
          <span class="fluxay"></span>
        </div>
        <div class="leg"></div>
      </div>
      <div class="pullDown o-p-3">
        <img src="~@/assets/pullDown.png">
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import { showDiv } from '@/utils/tool.js'
const viewport = ref(window.innerWidth);
function innerWindow() {
  viewport.value = window.innerWidth;
}

let ballOneTop = ref(0)
let ballOneleft = ref(0)
let ballTwoTop = ref(0)
let ballTwoleft = ref(0)
let ballThreeTop = ref(0)
let ballThreeft = ref(0)
let ballFourTop = ref(0)

let son1 = ref(null)
var timerOne
var timerTow
var timerThree
var timerFour
let count = ref(0)
onMounted(() => {
  leftBallOne()
  leftBallTwo()
  leftBallThree()
  leftBallFour()
  window.addEventListener("resize", innerWindow);
})
function leftBallOne() {
  up()
  function up() {
    count.value++
    clearInterval(timerOne)
    timerOne = setInterval(() => {
      ballOneTop.value -= 0.5
      if (ballOneTop.value < -20) {
        return down()
      }
    }, 30)
  }

  function down() {
    count.value++
    clearInterval(timerOne)
    timerOne = setInterval(() => {
      ballOneTop.value += 0.5
      if (ballOneTop.value >= 20) {
        return rightCircle()
      }
    }, 30)
  }

  function rightCircle() {
    clearInterval(timerOne)
    timerOne = setInterval(() => {
      if (ballOneTop.value <= 0) {
        return goLeft()
      }
      ballOneleft.value += 0.05
      ballOneTop.value = ballOneTop.value - (ballOneleft.value * 0.01)
    }, 3)
  }
  function goLeft() {
    clearInterval(timerOne)
    timerOne = setInterval(() => {
      ballOneleft.value -= 0.5
      if (ballOneleft.value <= 0) {
        return up()
      }
    }, 30)
  }

}
function leftBallTwo() {
  goLeft()
  function up() {
    count.value++
    clearInterval(timerTow)
    timerTow = setInterval(() => {
      ballTwoTop.value -= 0.5
      if (ballTwoTop.value <= 0) {
        return goLeft()
      }
    }, 30)
  }

  function down() {
    count.value++
    clearInterval(timerTow)
    timerTow = setInterval(() => {
      ballTwoTop.value += 0.5
      if (ballTwoTop.value >= 20) {
        return up()
      }
    }, 30)
  }

  function leftCircle() {
    clearInterval(timerTow)
    timerTow = setInterval(() => {
      if (ballTwoleft.value >= 0) {
        return down()
      }
      ballTwoTop.value -= 0.05
      ballTwoleft.value = ballTwoleft.value - (ballTwoTop.value * 0.01)
    }, 3)
  }
  function goLeft() {
    clearInterval(timerTow)
    timerTow = setInterval(() => {
      ballTwoleft.value -= 0.5
      if (ballTwoleft.value <= -20) {
        return leftCircle()
      }
    }, 30)
  }
}
function leftBallThree() {
  down()
  function up() {
    count.value++
    clearInterval(timerThree)
    timerThree = setInterval(() => {
      ballThreeTop.value -= 0.5
      if (ballThreeTop.value <= -20) {
        return down()
      }
    }, 30)
  }

  function down() {
    count.value++
    clearInterval(timerThree)
    timerThree = setInterval(() => {
      ballThreeTop.value += 0.5
      if (ballThreeTop.value >= 20) {
        return up()
      }
    }, 30)
  }
}
function leftBallFour() {
  up()
  function up() {
    count.value++
    clearInterval(timerFour)
    timerFour = setInterval(() => {
      ballFourTop.value -= 0.5
      if (ballFourTop.value <= -20) {
        return down()
      }
    }, 30)
  }

  function down() {
    count.value++
    clearInterval(timerFour)
    timerFour = setInterval(() => {
      ballFourTop.value += 0.5
      if (ballFourTop.value >= 20) {
        return up()
      }
    }, 30)
  }
}
</script>

<style lang="scss" scoped>
@keyframes circle {
  0% {
    transform: translate(-50%, -50%) scaleX(0.4) rotate(0);
  }

  100% {
    transform: translate(-50%, -50%) scaleX(0.4) rotate(360deg);
  }
}

@keyframes Fluxay {
  0% {
    transform: rotate(0) scaleY(0.6) scaleX(1.5);
  }

  50% {
    transform: rotate(-180deg) scaleY(0.4) scaleX(1);
  }

  100% {
    transform: rotate(-360deg) scaleY(0.6) scaleX(1.5);
  }
}

@media screen and (min-width: 500px) {
  .main {
    position: relative;
    background: url('~@/assets/nftCollection/light.png') top no-repeat;
    background-size: 100% 100;
    width: 100%;
    border-top: 1px solid transparent;
    opacity: 0;
  }

  .webTransition {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 0px auto 0;
    width: 1200px;
    height: 1036PX;

    .bridge {
      position: absolute;
      top: 0PX;
      left: 50%;
      transform: translateX(-50%);
      width: 455PX;

      img {
        width: 100%;
      }

      .circle {
        position: absolute;
        transform: translate(-50%, -50%) scaleX(0.4);
        top: 50%;
        left: 250PX;
        height: 550PX;
        width: 550PX;
        border-radius: 50%;
        // border: 1px solid #fff;
        z-index: 2;
        animation: circle 10s linear infinite;

        .fluxay {
          position: absolute;
          top: -50PX;
          left: 200PX;
          background: rgba($color: #fff, $alpha: 1);
          filter: blur(30PX);
          width: 100PX;
          height: 100PX;
          border-radius: 50%;
          // box-shadow: 10px 1px 10px 20px #fff;
          animation: Fluxay 10s linear infinite;
        }
      }

      .leg {
        position: absolute;
        background: url('~@/assets/nftCollection/leg.png') no-repeat;
        background-size: 100%;
        right: 0;
        bottom: 0;
        width: 198PX;
        height: 360PX;
        z-index: 3;
      }
    }

    .web1 {
      margin-top: 100px;
      width: 400PX;

      img {
        width: 325PX;
        height: 46PX;
      }

      .label {
        margin-top: 20px;
        display: flex;

        span {
          margin-right: 30PX;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 120PX;
          height: 40PX;
          background: url('~@/assets/nftCollection/border@2x.png') center no-repeat;
          background-size: 100%;
          font-size: 18PX;
          font-family: 'OPPOSans';
          font-weight: bold;
          color: #39D8E5;
        }


      }

      .linkBall {
        margin-top: 100PX;
        position: relative;

        .ball {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          position: absolute;
          top: 0;
          left: 0;
          width: 100PX;
          height: 100PX;
          background: url('~@/assets/nftCollection/ball.png') center no-repeat;
          background-size: 100% 100%;
          border-radius: 50%;

          span {
            font-size: 16PX;
            font-family: OPPOSans;
            font-weight: 500;
            color: #E9FDFF;
          }
        }

        .line {
          width: 100PX;
          height: 100PX;
        }

        .lineTow {
          position: absolute;
          transform: translateY(-100PX);
        }

        .content {
          width: 80PX;
          height: 80PX;
        }

        .ballOne {
          position: absolute;
          top: 20PX;
          left: 0;
        }

        .ballTwo {
          position: absolute;
          top: 100PX;
          left: 150PX;
        }

        .ballThree {
          position: absolute;
          top: 0;
          left: 300PX;
        }
      }

    }

    .web2 {
      margin-top: 100px;
      width: 400PX;

      img {
        width: 325PX;
        height: 46PX;
      }

      .label {
        margin-top: 20px;
        display: flex;

        span {
          margin-right: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 120PX;
          height: 40PX;
          background: url('~@/assets/nftCollection/border@2x.png') center no-repeat;
          background-size: 100%;
          font-size: 18PX;
          font-family: 'OPPOSans';
          font-weight: bold;
          color: #39D8E5;
        }
      }

      .linkBall {
        margin-top: 100px;
        position: relative;

        .ball {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          position: absolute;
          top: 0;
          left: 0;
          width: 100PX;
          height: 100PX;
          background: url('~@/assets/nftCollection/ball.png') center no-repeat;
          background-size: 100% 100%;
          border-radius: 50%;

          span {
            font-size: 16PX;
            font-family: OPPOSans;
            font-weight: 500;
            color: #E9FDFF;
          }
        }

        .line {
          width: 80px;
          height: 80px;
        }

        .lineTow {
          position: absolute;
          transform: translateY(-100PX);
        }

        .content {
          width: 80PX;
          height: 80PX;
        }

        .ballOne {
          position: absolute;
          top: 80PX;
          left: 0;
        }

        .ballTwo {
          position: absolute;
          top: 0px;
          left: 150PX;
        }

        .ballThree {
          position: absolute;
          top: 120PX;
          left: 300PX;
        }
      }
    }

    .bisect {
      max-width: 455px;
      // min-width: 300PX;
    }
  }

  .pullDown {
    position: absolute;
    left: 0;
    bottom: 150px;
    width: 100%;
    display: flex;
    justify-content: center;


    img {
      width: 56px;
      height: 40px;
    }
  }
}

@media screen and (min-width:359px) and (max-width: 500px) {
  .img-left {
    margin-top: 300px;
  }
  .main {
    position: relative;
    background: url('~@/assets/nftCollection/light.png') top no-repeat;
    background-size: 100% 100%;
    width: 100%;
    border-top: 1px solid transparent;
    opacity: 0;
  }
  .static_label {
    margin-top: 50px;
    &_item {
      margin-top: 10px;
      width: 50PX;
      height: 50PX;
      background: url('~@/assets/nftCollection/ball.png') center no-repeat;
      background-size: 100% 100%;
      border-radius: 50%;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &_line {
      margin-left: 40px;
      margin-top: -12px;
      width: 1px;
      height: 40px;
      background: rgb(86, 223, 245);
      opacity: 0.5;
      transform: rotate(-40deg);
    }
    &_line2 {
      margin-left: 40px;
      margin-top: -12px;
      width: 1px;
      height: 40px;
      background: rgb(86, 223, 245);
      opacity: 0.5;
      transform: rotate(40deg);
    }
    .s-left {
      margin-left: 40px;
      margin-top: -15px;
    }
    .s-top {
      margin-top: -10px;
    }
    .s-right {
      margin-left: 40px;
    }
    .line-right {
      transform: rotate(40deg);
    }
    .s-t {
      margin-top: -10px
    }
  }
  .right-200 {
    position: absolute;
    right: 30px;
  }
  .webTransition {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 0px auto 0;
    width: 100%;
    height: 420PX;

    .bridge {
      position: absolute;
      top: 0PX;
      left: 50%;
      transform: translateX(-50%);
      width: 200PX;

      img {
        width: 100%;
      }

      .circle {
        position: absolute;
        transform: translate(-50%, -50%) scaleX(0.4);
        top: 50%;
        left: 100PX;
        height: 50PX;
        width: 50PX;
        border-radius: 50%;
        // border: 1px solid #fff;
        z-index: 2;
        animation: circle 10s linear infinite;

        .fluxay {
          position: absolute;
          top: -50PX;
          left: -100PX;
          background: rgba($color: #fff, $alpha: 1);
          filter: blur(30PX);
          width: 50PX;
          height: 50PX;
          border-radius: 50%;
          // box-shadow: 10px 1px 10px 20px #fff;
          // animation: Fluxay 10s linear infinite;
        }
      }

      .leg {
        position: absolute;
        background: url('~@/assets/nftCollection/leg.png') no-repeat;
        background-size: 100%;
        right: 0;
        bottom: 0;
        width: 90PX;
        height: 160PX;
        z-index: 3;
      }
    }

    .web1 {
      margin-top: -40px;
      margin-left: 20px;
      width: 200PX;
      img {
        width: 150PX;
        height: 23PX;
      }
      .label {
        display: flex;
        span {
          margin-right: 15PX;
          margin-top: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 90PX;
          height: 30PX;
          background: url('~@/assets/nftCollection/border@2x.png') center no-repeat;
          background-size: 100% 100%;
          font-size: 12PX;
          font-family: 'OPPOSans';
          font-weight: bold;
          color: #39D8E5;
        }
      }

      .linkBall {
        margin-top: 10PX;
        position: relative;

        .ball {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          position: absolute;
          top: 0;
          left: 0;
          width: 50PX;
          height: 50PX;
          background: url('~@/assets/nftCollection/ball.png') center no-repeat;
          background-size: 100% 100%;
          border-radius: 50%;

          span {
            font-size: 12PX;
            font-family: OPPOSans;
            font-weight: 500;
            color: #E9FDFF;
          }
        }

        .line {
          width: 50PX;
          height: 500PX;
        }

        .lineTow {
          position: absolute;
          transform: translateY(-100PX);
        }

        .content {
          width: 80PX;
          height: 80PX;
        }

        .ballOne {
          position: absolute;
          top: 20PX;
          left: 0;
        }

        .ballTwo {
          position: absolute;
          top: 100PX;
          left: 150PX;
        }

        .ballThree {
          position: absolute;
          top: 0;
          left: 300PX;
        }
      }

    }

    .web2 {
      margin-top: 0px;
      position: absolute;
      right: 0px;


      img {
        width: 150PX;
        height: 23PX;
        margin-left: 45px;
      }

      .label {
        display: flex;
        // margin-left: -15px;
        span {
          margin-right: 15px;
          margin-top: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 90px;
          height: 30px;
          background: url('~@/assets/nftCollection/border@2x.png') center no-repeat;
          background-size: 100%;
          font-size: 12px;
          font-family: 'OPPOSans';
          font-weight: bold;
          color: #39D8E5;
        }
      }

      .linkBall {
        margin-top: 100px;
        position: relative;

        .ball {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          position: absolute;
          top: 0;
          left: 0;
          width: 100PX;
          height: 100PX;
          background: url('~@/assets/nftCollection/ball.png') center no-repeat;
          background-size: 100% 100%;
          border-radius: 50%;

          span {
            font-size: 16PX;
            font-family: OPPOSans;
            font-weight: 500;
            color: #E9FDFF;
          }
        }

        .line {
          width: 80px;
          height: 80px;
        }

        .lineTow {
          position: absolute;
          transform: translateY(-100PX);
        }

        .content {
          width: 80PX;
          height: 80PX;
        }

        .ballOne {
          position: absolute;
          top: 80PX;
          left: 0;
        }

        .ballTwo {
          position: absolute;
          top: 0px;
          left: 150PX;
        }

        .ballThree {
          position: absolute;
          top: 120PX;
          left: 300PX;
        }
      }
    }

    .bisect {
      max-width: 455px;
      // min-width: 300PX;
    }
  }

  .pullDown {
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 100%;
    display: flex;
    justify-content: center;


    img {
      width: 36px;
      height: 26px;
    }
  }
}

</style>
