<template>
  <div class="footer" @click="showClose">
    <div class="footer_title">咨询我们，了解更多</div>
    <div class="footer_list f-s-16">
      <div class="footer_list_left">
        <span>7*24小时技术支持</span>
        <span>一对一商务沟通</span>
        <span>多种套餐和价格体系</span>
        <span>个性化解决方案</span>
      </div>
      <div class="footer_list_center">
        <span>联系我们</span>
        <span>电话:19381916073</span>
        <span>邮箱:admin@0block.com</span>
        <span>地址:四川成都高新区天府新谷10号楼9楼</span>
      </div>
      <div class="footer_list_right">
        <span>二维码</span>
        <div class="code display_flex">
          <div class="code_left">
            <div class="code_left_url">
              <img src="../assets/footer/wechat.png" alt="">
            </div>
            <div class="code_left_title">微信公众号</div>
          </div>
          <div class="code_right">
            <div class="code_right_url">
              <img src="../assets/footer/sina.png" alt="">
            </div>
            <div class="code_right_title">微博</div>
          </div>
        </div>
      </div>
    </div>
    <div class="filing"><a href="https://beian.miit.gov.cn/">蜀ICP备19036902号-6 0block.com</a></div>
  </div>

</template>

<script setup>
  import { useStore } from 'vuex'
  const store = useStore()
  const showClose = () => {
    store.commit('setHideClose', 1)
  }
</script>

<style lang="scss" scoped>
@media screen and (min-width: 500px) {
  .footer {
    padding-top: 300px;
    padding-bottom: 60px;

    &_title {
      font-size: 70px;
      color: #FFFFFF;
    }

    &_list {
      padding-top: 132px;
      display: flex;
      justify-content: center;

      &_left {
        display: flex;
        flex-direction: column;

        span {
          padding-top: 36px;
          text-align: left;
        }
      }

      &_center {
        padding-left: 180px;
        display: flex;
        flex-direction: column;

        span {
          padding-top: 36px;
          text-align: left;
        }
      }

      &_right {
        padding-left: 180px;
        display: flex;
        flex-direction: column;

        span {
          padding-top: 36px;
          text-align: left;
        }

        .code {
          padding-top: 32px;

          &_left {
            &_url {
              width: 100px;
              height: 100px;
              background: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 88px;
                height: 88px;
              }
            }

            &_title {
              padding-top: 20px;
            }
          }

          &_right {
            margin-left: 22px;

            &_url {
              width: 100px;
              height: 100px;
              background: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 88px;
                height: 88px;
              }
            }

            &_title {
              padding-top: 20px;
            }
          }
        }
      }
    }

    .filing {
      margin-top: 190px;

      a {

        color: rgb(112, 109, 109) !important;
      }
    }
  }
}

@media screen and (min-width:359px) and (max-width: 500px) {
  .footer {
    padding-top: 50px;
    padding-bottom: 30px;

    &_title {
      font-size: 24px;
      color: #FFFFFF;
    }

    &_list {
      padding-top: 30px;
      font-size: 14px;
      padding-left: 50px;

      &_left {
        display: flex;
        flex-direction: column;

        span {
          padding-top: 16px;
          text-align: left;
        }
      }

      &_center {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        span {
          padding-top: 16px;
          text-align: left;
        }
      }

      &_right {
        display: flex;
        margin-top: 20px;
        flex-direction: column;

        span {
          padding-top: 16px;
          text-align: left;
        }

        .code {
          padding-top: 16px;

          &_left {
            &_url {
              width: 100px;
              height: 100px;
              background: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 88px;
                height: 88px;
              }
            }

            &_title {
              padding-top: 16px;
            }
          }

          &_right {
            margin-left: 22px;

            &_url {
              width: 100px;
              height: 100px;
              background: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 88px;
                height: 88px;
              }
            }

            &_title {
              padding-top: 16px;
            }
          }
        }
      }
    }

    .filing {
      margin-top: 30px;

      a {
        font-size: 12px !important;
        font-weight: 400 !important;
        color: #fff !important;
        opacity: 0.7;
      }
    }
  }
}
</style>
