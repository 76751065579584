<template>
  <div class="MetaverseGame wgt-fade-animate appear">
    <div class="game_exploit">
      <div class="game_exploit_title">
        <img src="~@/assets/metaverse/gameTitle.png" alt="">
      </div>
      <div v-if="viewport > 500" class="game_exploit_content o-8">
        元宇宙是整合多种新技术而产生的新型虚实相融的互联网应用和社会形态，它基于扩展现实技术提供沉浸式体验，基于数字孪生技术生成现实世界的镜像，基于区块链技<br />术搭建经济体系，将虚拟世界与现实世界在经济系统、社会系统、身份系统上密切融合，并允许每个用户进行内容生产和世界编辑。
      </div>
      <div v-else class="game_exploit_content o-8">
        元宇宙是整合多种新技术而产生的新型虚实相融的互联网应用和社会形态，它基于扩展现实技术提供沉浸式体验，基于数字孪生技术生成现实世界的镜像，基于区块链技术搭建经济体系，将虚拟世界与现实世界在经济系统、社会系统、身份系统上密切融合，并允许每个用户进行内容生产和世界编辑。
      </div>
      <div class="game_exploit_consult c-o" ref="consultSubmit"
        @click="consultShow"
        @mouseover="mouseOver"
        @mouseleave="mouseLeave">
        立即咨询
      </div>
      <!-- <div class="pullDown o-p-3">
        <img src="~@/assets/pullDown.png" alt="">
      </div> -->
    </div>
    <div class="game_show flex_justify_center">
      <div class="game_show_item">
        <img class="game_show_item_logo" src="~@/assets/metaverse/farmersworld.png" alt="">
        <div class="game_show_item_title">农民世界游戏</div>
        <div class="game_show_item_content">
          经营模拟类游戏，开垦养殖买入卖出产品扩大经营。
        </div>
      </div>
      <div class="game_show_item m-l-60">
        <img class="game_show_item_logo" src="~@/assets/metaverse/walkdog.png" alt="">
        <div class="game_show_item_title">遛狗</div>
        <div class="game_show_item_content">
          进入PlayStation VR Worlds经历4段独特的PlayStation VR的游玩体验。
        </div>
      </div>
      <div class="game_show_item m-l-60">
        <img class="game_show_item_logo" src="~@/assets/metaverse/more.png" alt="">
        <div class="game_show_item_title">更多元宇宙游戏敬请期待。。。</div>
        <div class="game_show_item_content">

        </div>
      </div>
    </div>
    <Consult v-if="dialogVisible === true" @showClose="showClose" />
  </div>
</template>

<script setup>
import { defineEmits, ref, onMounted } from 'vue'
const emits = defineEmits(['consultEmit'])
const consultSubmit = ref(null)
const consultShow = () => {
  emits('consultEmit')
}
const mouseOver = () => {
  consultSubmit.value.style.borderImage = 'linear-gradient(45deg, #31CDB0, #31CDB0, #31CDB0) 10 10'
}
const mouseLeave = () => {
  consultSubmit.value.style.borderImage = 'linear-gradient(45deg, #3551A4, #2C8EC4, #31CDB0) 10 10'
}

const viewport = ref(window.innerWidth);
function innerWindow() {
  viewport.value = window.innerWidth;
}

onMounted(() => {
  window.addEventListener("resize", innerWindow);
})
</script>

<style lang="scss" scoped>
@media screen and (min-width: 500px) {
  .MetaverseGame {
    .game_exploit {
      width: 100%;
      height: 960px;
      background: url('~@/assets/metaverse/gameBanner.png') center no-repeat;
      // background-size: 100%;
      padding-top: 230px;
      position: relative;

      &_title {
        width: 595px;
        height: 137px;
        margin: 0 auto;
      }

      &_content {
        font-size: 16px;
        line-height: 32px;
        padding-top: 41px;
      }

      &_consult {
        margin: 0 auto;
        margin-top: 137px;
        width: 200px;
        height: 70px;
        line-height: 70px;
        background: rgba(0, 255, 234, 0.1);
        border: 2px solid;
        border-image: linear-gradient(45deg, #3551A4, #2C8EC4, #31CDB0) 10 10;
        font-size: 20px;
        color: #43FBFF;
      }

      .pullDown {
        position: absolute;
        bottom: 116px;
        width: 100%;
        display: flex;
        justify-content: center;

        img {
          width: 56px;
          height: 40px;
        }
      }
    }

    .game_show {
      &_item {
        width: 360px;
        height: 600px;
        background: rgba(171, 214, 255, 0.15);
        padding: 20px;

        &_logo {
          width: 320px;
          height: 320px;
        }

        &_title {
          padding-top: 39px;
          text-align: left;
          font-size: 18px;
          font-weight: 400;
          color: #FFFFFF;
        }

        &_content {
          padding-top: 16px;
          text-align: left;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 24px;
          opacity: 0.6;
        }
      }

      .m-l-60 {
        margin-left: 60px;
      }
    }
  }
}

@media screen and (min-width:359px) and (max-width: 500px) {
  .MetaverseGame {
    .game_exploit {
      width: 100%;
      height: 460px;
      background: url('~@/assets/metaverse/gameBanner.png') center no-repeat;
      background-size: 100%;
      position: relative;

      &_title {
        width: 70%;
        height: 30px;
        margin: 0 auto;
      }

      &_content {
        font-size: 16px;
        line-height: 30px;
        padding-top: 60px;
        padding-left: 20px;
        padding-right: 20px
      }

      &_consult {
        margin: 0 auto;
        margin-top: 40px;
        width: 120px;
        height: 47px;
        line-height: 47px;
        background: rgba(0, 255, 234, 0.1);
        border: 2px solid;
        border-image: linear-gradient(45deg, #3551A4, #2C8EC4, #31CDB0) 10 10;
        font-size: 16px;
        color: #43FBFF;
      }

      .pullDown {
        position: absolute;
        bottom: 116px;
        width: 100%;
        display: flex;
        justify-content: center;

        img {
          width: 56px;
          height: 40px;
        }
      }
    }

    .game_show {
      display: flex;
      flex-direction: column;
      &_item {
        margin-top: 30px;
        width: 86%;
        background: rgba(171, 214, 255, 0.15);
        padding: 20px;

        &_logo {
          width: 320px;
          height: 320px;
        }

        &_title {
          padding-top: 39px;
          text-align: left;
          font-size: 18px;
          font-weight: 400;
          color: #FFFFFF;
        }

        &_content {
          padding-top: 16px;
          text-align: left;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 24px;
          opacity: 0.6;
        }
      }

      .m-l-60 {
      }
    }
  }
}
</style>
