<template>
  <div class="digitalIdentity wgt-fade-animate appear">
    <div class="identity" id="digitalIdentity">
      <div class="identity_title">
        <!-- <img src="~@/assets/metaverse/identityTitle.png" alt=""> -->
      </div>
      <div v-if="viewport > 500" class="identity_content o-8">
        极数链科技专注于元宇宙入口的搭建，已具备完善的数字身份底层框架，为您提供精美的人物形象及换衣风格。<br />您可以根据自己的需求生成完善的数字身份，随时随地进入元宇宙世界！
      </div>
      <div v-else class="identity_content o-8">
        极数链科技专注于元宇宙入口的搭建，已具备完善的数字身份底层框架，为您提供精美的人物形象及换衣风格。您可以根据自己的需求生成完善的数字身份，随时随地进入元宇宙世界！
      </div>
      <div class="identity_consult c-o" ref="consultSubmit"
        @click="consultShow"
        @mouseover="mouseOver"
        @mouseleave="mouseLeave">
        立即咨询
      </div>
      <!-- <div class="pullDown o-p-3">
        <img src="~@/assets/pullDown.png" alt="">
      </div> -->
    </div>
  </div>
</template>

<script setup>
import { defineEmits, ref, onMounted } from 'vue'
const emits = defineEmits(['consultEmit'])
const consultSubmit = ref(null)
const consultShow = () => {
  emits('consultEmit')
}
const mouseOver = () => {
  consultSubmit.value.style.borderImage = 'linear-gradient(45deg, #31CDB0, #31CDB0, #31CDB0) 10 10'
}
const mouseLeave = () => {
  consultSubmit.value.style.borderImage = 'linear-gradient(45deg, #3551A4, #2C8EC4, #31CDB0) 10 10'
}

const viewport = ref(window.innerWidth);
function innerWindow() {
  viewport.value = window.innerWidth;
}

onMounted(() => {
  window.addEventListener("resize", innerWindow);
})
</script>

<style lang="scss" scoped>
@media screen and (min-width: 500px) {
  .digitalIdentity {
    .identity {
      width: 100%;
      height: 960px;
      background: url('~@/assets/metaverse/identityBanner.png') center no-repeat;
      padding-top: 223px;
      position: relative;

      &_title {
        margin: 0 auto;
        width: 924px;
        height: 66px;
        background: url('~@/assets/metaverse/identityTitle.png') center no-repeat;
      }

      &_content {
        margin-top: 34px;
        font-size: 20px;
        font-weight: 300;
        color: #FFFFFF;
      }

      &_consult {
        width: 200px;
        font-size: 20px;
        color: #43FBFF;
        height: 70px;
        line-height: 70px;
        background: rgba(0, 255, 234, 0.1);
        border: 2px solid;
        border-image: linear-gradient(45deg, #3551A4, #2C8EC4, #31CDB0) 10 10;
        margin: 0 auto;
        margin-top: 100px;
      }

      .pullDown {
        position: absolute;
        bottom: 114px;
        width: 100%;
        display: flex;
        justify-content: center;

        img {
          width: 56px;
          height: 40px;
        }
      }
    }
  }
}

@media screen and (min-width:359px) and (max-width: 500px) {
  .digitalIdentity {
    .identity {
      width: 100%;
      height: 500px;
      background: url('~@/assets/metaverse/identityBanner.png') center no-repeat;
      background-size: 100% 100%;
      padding-top: 50px;
      position: relative;

      &_title {
        margin: 0 auto;
        width: 90%;
        height: 30px;
        background: url('~@/assets/metaverse/identityTitle.png') center no-repeat;
        background-size: 100% 100%;
      }

      &_content {
        margin-top: 34px;
        padding: 0px 20px;
        font-size: 16px;
        font-weight: 300;
        line-height: 30px;
        color: #FFFFFF;
      }

      &_consult {
        width: 120px;
        font-size: 16px;
        color: #43FBFF;
        height: 47px;
        line-height: 47px;
        background: rgba(0, 255, 234, 0.1);
        border: 2px solid;
        border-image: linear-gradient(45deg, #3551A4, #2C8EC4, #31CDB0) 10 10;
        margin: 0 auto;
        margin-top: 100px;
      }

      .pullDown {
        position: absolute;
        bottom: 114px;
        width: 100%;
        display: flex;
        justify-content: center;

        img {
          width: 56px;
          height: 40px;
        }
      }
    }
  }
}
</style>
